import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Push from "../components/push"
import EasySection from "../components/easy_section"
import ChromeSection from "../components/chrome_section"
import DomainSection from "../components/domain_section"
import UsersSection from "../components/users_section"
import DashboardSection from "../components/dashboard_section"
import DetailsSection from "../components/details_section"
import CallToActionSection from "../components/call_to_action_section"
import DocumentationSection from "../components/documentation_section"

class Documentation extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="API Documentation" slug="/documentation/" />
        <DocumentationSection />
        <CallToActionSection />
      </Layout>
    )
  }
}

export default Documentation

export const pageQuery = graphql`
  query {
          site {
          siteMetadata {
          title
        }
        }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
          edges {
          node {
          excerpt
          fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
      title
      description
    }
  }
}
}
}
`
