import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

class DocumentationSection extends React.Component {

  state = {
    key: this.getUrlParameter("key") || (typeof window !== 'undefined' && window.localStorage['hyperkey']),
    title: "",
    body: "",
    sound: "",
    badgeCount: "",
    redirectUrl: "",
    slackWebhookUrl: "",
    preview: "",
    curl: "",
    javascript: "",
    rawUrl: ""
  };

  getUrlParameter(name) {
    if (typeof window === 'undefined') { return ""; }
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  handleChange(parameterName, event) {
    let keyValue = this.state.key ? `&key=${encodeURIComponent(this.state.key)}` : '';
    let titleValue = this.state.title ? `&title=${encodeURIComponent(this.state.title)}` : '';
    let bodyValue = this.state.body ? `&body=${encodeURIComponent(this.state.body)}` : '';
    let soundValue = this.state.sound ? `&sound=${encodeURIComponent(this.state.sound)}` : '';
    let badgeCountValue = this.state.badgeCount ? `&badgeCount=${encodeURIComponent(this.state.badgeCount)}` : '';
    let redirectUrlValue = this.state.redirectUrl ? `&redirectUrl=${encodeURIComponent(this.state.redirectUrl)}` : '';
    let slackWebhookUrlValue = this.state.slackWebhookUrl ? `&slackWebhookUrl=${encodeURIComponent(this.state.slackWebhookUrl)}` : '';

    let url = `https://usehyperlink.com/api?action=send${keyValue}${titleValue}${bodyValue}${soundValue}${badgeCountValue}${redirectUrlValue}${slackWebhookUrlValue}`;

    var state = this.state;
    if (event && event.target) {
      state[parameterName] = event.target.value;
    }
    this.setState({
      ...state,
      rawUrl: url,
      curl: `curl "${url}"`,
      javascript: `var request = new XMLHttpRequest();\nrequest.open('GET', '${url}');\nrequest.send();`,
      preview: url
    });

    if (typeof window !== 'undefined' && keyValue) {
      window.localStorage['hyperkey'] = keyValue;
    }
  }

  copyLink() {
    var copyText = document.getElementById("preview");
    copyText.select();
    copyText.setSelectionRange(0, copyText.value.length);
    document.execCommand("copy");
    alert("Url copied!");

  }

  testSend() {
    var request = new XMLHttpRequest();
    request.open('GET', document.getElementById("preview").value, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.send();
  }

  selectAll() {
    var copyText = document.getElementById("preview");
    copyText.select();
    copyText.setSelectionRange(0, copyText.value.length);
  }

  componentWillMount() {
    this.handleChange();
  }

  render() {
    return (
      <div className="container api">
        <h1>API Documentation</h1>
        <p>
          Programatically send yourself Hyperlink push notifications with 3 lines of Javascript or a single curl command.
      <br /><br />
          Try entering <i>Hello world</i> in the <b>Notification title</b> field below and click <b>Send test!</b> to try it out.
    </p>
        <h2>Notification Builder</h2>
        <div className="form">
          <div className="input">
            <input id="title" placeholder="Notification title" autoCapitalize="none" type="text" onKeyUp={this.handleChange.bind(this, "title")}
              onKeyDown={this.handleChange.bind(this, "title")} onChange={this.handleChange.bind(this, "title")} />
            <div className="details">
              Available substitutions include <b>{'{'}city{'}'}</b>, <b>{'{'}region{'}'}</b>, <b>{'{'}device{'}'}</b>, <b>{'{'}platform{'}'}</b>, <b>{'{'}person{'}'}</b>
            </div>
            <input id="body" placeholder="Optional: Notification body" autoCapitalize="none" type="text"
              onKeyUp={this.handleChange.bind(this, "body")}
              onKeyDown={this.handleChange.bind(this, "body")} onChange={this.handleChange.bind(this, "body")} />
            <div className="details">
              Available substitutions include <b>{'{'}city{'}'}</b>, <b>{'{'}region{'}'}</b>, <b>{'{'}device{'}'}</b>, <b>{'{'}platform{'}'}</b>, <b>{'{'}person{'}'}</b>
            </div>
            <input id="sound" placeholder="Optional: Sound" autoCapitalize="none" type="text" onKeyUp={this.handleChange.bind(this, "sound")}
              onKeyDown={this.handleChange.bind(this, "sound")} onChange={this.handleChange.bind(this, "sound")} />
            <div className="details">
              Allowed values are <b>default</b>. This will play the default system sound.
        </div>
            <input id="badgeCount" placeholder="Optional: Badge Count" autoCapitalize="none" type="text"
              onKeyUp={this.handleChange.bind(this, "badgeCount")}
              onKeyDown={this.handleChange.bind(this, "badgeCount")} onChange={this.handleChange.bind(this, "badgeCount")} />
            <div className="details">
              Value must be a number. Setting <b>0</b> will clear badge count.
        </div>
            <input defaultValue={this.state.key} id="key" placeholder="Your HyperKey" autoCapitalize="none" type="text" onKeyUp={this.handleChange.bind(this, "key")}
              onKeyDown={this.handleChange.bind(this, "key")} onChange={this.handleChange.bind(this, "key")} />
            <div className="details">
              Download the <a target="_blank"
                href="https://apps.apple.com/us/app/hyperlink-custom-push-links/id1480418373?ls=1">Hyperlink app</a>,
          tap the <b>☰</b> icon in the top left,
          tap <b>Copy HyperKey</b>
            </div> <input id="redirectUrl" placeholder="Optional: Redirect Url" autoCapitalize="none" type="url"
              onKeyUp={this.handleChange.bind(this, "redirectUrl")}
              onKeyDown={this.handleChange.bind(this, "redirectUrl")} onChange={this.handleChange.bind(this, "redirectUrl")} />
            <div className="details">
              Destination that the URL will redirect to if opened in a web browser.
        </div>
            <input id="slackWebhookUrl" placeholder="Optional: Slack Webhook Url" autoCapitalize="none" type="url"
              onKeyUp={this.handleChange.bind(this, "slackWebhookUrl")}
              onKeyDown={this.handleChange.bind(this, "slackWebhookUrl")} onChange={this.handleChange.bind(this, "slackWebhookUrl")} />
            <div className="details">
              Post messages to a Slack channel. Get a Slack webhook URL for your channel <a target="_blank"
                href="https://slack.com/help/articles/115005265063#">here</a>.
        </div>
            <input onClick={this.selectAll.bind(this)} readOnly={true} className="preview" id="preview" value={this.state.rawUrl}
              autoCapitalize="none" type="url" />
            <div className="details">
              This is your Hyperlink. Making a GET request for it will trigger a push to be sent.
        </div>
          </div>
          <div className="buttons">
            <button onClick={this.testSend.bind(this)}>
              Send test!
        </button>
            <button onClick={this.copyLink.bind(this)}>
              Copy link!
        </button>
          </div>
        </div>
        <hr />
        <h2>Raw URL</h2>
        <p id="url" className="code">
          {this.state.rawUrl}
        </p>
        <hr />
        <h2>curl Command</h2>
        <p id="curl" className="code">
          {this.state.curl}
        </p>
        <hr />
        <h2>Javascript</h2>
        <p id="javascript" className="code">
          {this.state.javascript}
        </p>
        <hr />
        <h2>Documentation</h2>
        <h3>Base URL</h3>
        <p>
          The base url for all api requests is <a href="https://usehyperlink.com/api">https://usehyperlink.com/api</a>.
          All parameters are passed in as query parameters. The api is accessed by sending GET requests to the url.
      <br /><br />
          You can use the Notification Builder above to construct a properly formatted URL with your desired parameters and
          see the
          associated raw link, curl command or Javascript snippet.
    </p>
        <h3>Parameters</h3>
        <p>
          Parameters are passed in as standard URL query parameters. The first query parameter is preceded by a question
      mark character <b>?</b>, while the following are delimited by the ampersand character <b>&amp;</b>.
      <br /><br />
          For more information on the query parameter format, <a target="_blank"
            href="https://en.wikipedia.org/wiki/Query_string">click here</a>.
    </p>
        <h3>action parameter</h3>
        <p>
          The <b>action</b> parameter specifies what action should be taken. The default value is <b>send</b>.
      <br /><br />
          Currently, the there is only one acceptable value for this parameter, and it can be omitted, but more actions may
          be added in the future.
    </p>
        <h3>key parameter</h3>
        <p>
          The <b>key</b> parameter contains your personal HyperKey. This is an alphanumeric string that tells Hyperlink
          which device to send the push notification to.
      <br /><br />
          In order to get your HyperKey, you must download the <a target="_blank"
            href="https://apps.apple.com/us/app/hyperlink-custom-push-links/id1480418373?ls=1">Hyperlink app</a>,
      tap the <b>☰</b> icon in the top left,
      then tap the <b>Copy HyperKey</b> key. This will copy your HyperKey to your clipboard, which can then be pasted
                                                                                                                                                                                                                                                                                                                        into the builder above.
    </p>
        <h3>title parameter</h3>
        <p>
          The <b>title</b> parameter contains the title for the push notification you want to send. This is a string that
          should ideally fit in one line and will be truncated by the system if it doesn't.
      <br /><br />
          If your title parameter contains any characters that are not url safe, including <b>, / ? : @ & = + $ #</b> you
          should url-encode the value of the parameter. In Javascript this can be done with a function like
          encodeURIComponent(value).
      <br /><br />
          Available substitutions include <b>{'{'}city{'}'}</b>, <b>{'{'}region{'}'}</b>, <b>{'{'}device{'}'}</b>, <b>{'{'}platform{'}'}</b>, <b>{'{'}person{'}'}</b>. See the Substitutions section for more information.
    </p>
        <h3>body parameter</h3>
        <p>
          The <b>body</b> parameter contains the body text for the push notification you want to send. This is a string that
          can wrap to multiple lines.
      <br /><br />
          If your body parameter contains any characters that are not url safe, including <b>, / ? : @ & = + $ #</b> you
          should url-encode the value of the parameter. In Javascript this can be done with a function like
          encodeURIComponent(value).
      <br /><br />
          Available substitutions include <b>{'{'}city{'}'}</b>, <b>{'{'}region{'}'}</b>, <b>{'{'}device{'}'}</b>, <b>{'{'}platform{'}'}</b>, <b>{'{'}person{'}'}</b>. See the Substitutions section for more information.
    </p>
        <h3>sound parameter</h3>
        <p>
          The <b>sound</b> parameter controls which sound is played when a notification is recieved. The only currently
      supported value is <b>default</b>. Support for more values may be added in the future. Omitting this parameter
                                                                                                                                                                                                                                                                                                                        send the push notification silently.
    </p>
        <h3>badgeCount parameter</h3>
        <p>
          The <b>badgeCount</b> parameter controls the red badge counter for the Hyperlink app. This must be a numeric
      integer value. Omitting this parameter leaves the app's badge count unchanged. Sending a value of <b>0</b> clears
                                                                                                                                                                                                                                                                                                                        the app's badge count.
    </p>
        <h3>redirectUrl parameter</h3>
        <p>
          The <b>redirectUrl</b> parameter controls the url that the user will be redirected to if this link is visited in a
          web browser. The value of this url should be url-encoded. In Javascript this can be done with a function like
          encodeURIComponent(value). If the value does not begin with http:// or https:// - a prefix of http:// will be
          added automatically.
    </p>
        <h3>slackWebhookUrl parameter</h3>
        <p>
          The <b>slackWebhookUrl</b> parameter enables clicks on this url to post messages to a Slack channel. This webhook
      url can be obtained for your desired channel <a target="_blank"
            href="https://slack.com/help/articles/115005265063#">here</a>. For now this requires you to create a Slack app
(lightweight process explained in link), but support for one-click Slack support will be added in a future version
of the API.
    </p>
        <h3>Substitutions</h3>
        <p>
          The <b>title</b> and <b>body</b> parameters support the following substitutions which will be replaced when the
          push notification is sent:
      </p>
        <ul>
          <li><b>{'{'}city{'}'}</b> - an approximation of the city that this request was sent from. This is based on the IP
          address from which the request is made. Example value: <i>San Francisco</i>, <i>New York</i>.</li>
          <li><b>{'{'}region{'}'}</b> - an approximation of the country or region that this request was sent from. This is based
          on the IP address from which the request is made. Example values: <i>US</i>, <i>FR</i>.</li>
          <li><b>{'{'}device{'}'}</b> - an approximation of the device that this request was sent from. This is based on the
          <i>user-agent</i> request header. Example value: <i>iPhone</i>.</li>
          <li><b>{'{'}platform{'}'}</b> - an approximation of the platform that this request was sent from. This is based on the
          <i>user-agent</i> request header. Example value: <i>Instagram</i>.</li>
          <li><b>{'{'}person{'}'}</b> - an approximation for whether or not this request was made by a human. The two currently
          supported values are <i>Someone</i> which means we think the request was made by a human or <i>A bot</i> if we
          think the request was made by a scraper or another automated process.</li>
        </ul>
        <p>
          If any of these values cannot be determined, they will be replaced with the string <b>Unknown</b>.
    </p>
      </div >

    );
  }
}

export default DocumentationSection;